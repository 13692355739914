.lobby-waiting-player-container {
  height: 60px;
  display: flex;
}

.lobby-waiting-player {
  align-self: flex-end;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
}

.player-alive {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.player-dead {
  opacity: 0.2;
  transition: opacity 500ms ease-in;
}

.bounce { 
  animation-timing-function: ease-out; 
  animation-iteration-count: infinite;
}

.duration-0 { animation-duration: 3.0s; }
.duration-1 { animation-duration: 3.1s; }
.duration-2 { animation-duration: 3.2s; }
.duration-3 { animation-duration: 3.3s; }
.duration-4 { animation-duration: 3.4s; }
.duration-5 { animation-duration: 3.5s; }
.duration-6 { animation-duration: 3.6s; }
.duration-7 { animation-duration: 3.7s; }
.duration-8 { animation-duration: 3.8s; }
.duration-9 { animation-duration: 3.9s; }

.bounce-0 { animation-name: bounce-0; }
.bounce-1 { animation-name: bounce-1; }
.bounce-2 { animation-name: bounce-2; }
.bounce-3 { animation-name: bounce-3; }
.bounce-4 { animation-name: bounce-4; }
.bounce-5 { animation-name: bounce-5; }
.bounce-6 { animation-name: bounce-6; }
.bounce-7 { animation-name: bounce-7; }
.bounce-8 { animation-name: bounce-8; }
.bounce-9 { animation-name: bounce-9; }

@keyframes bounce-0 {
  0%   { transform: translateY(0); box-shadow: none; }
  5%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  10% { transform: translateY(0); box-shadow: none;}
}

@keyframes bounce-1 {
  10%   { transform: translateY(0); box-shadow: none; }
  15%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  20% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-2 {
  20%   { transform: translateY(0); box-shadow: none; }
  25%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  30% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-3 {
  30%   { transform: translateY(0); box-shadow: none; }
  35%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  40% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-4 {
  40%   { transform: translateY(0); box-shadow: none; }
  45%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  50% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-5 {
  50%   { transform: translateY(0); box-shadow: none; }
  55%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  60% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-6 {
  60%   { transform: translateY(0); box-shadow: none; }
  65%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  70% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-7 {
  70%   { transform: translateY(0); box-shadow: none; }
  75%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  80% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-8 {
  80%   { transform: translateY(0); box-shadow: none; }
  85%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  90% { transform: translateY(0); box-shadow: none; }
}

@keyframes bounce-9 {
  90%   { transform: translateY(0); box-shadow: none; }
  95%  { transform: translateY(-5px); box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);}
  100% { transform: translateY(0); box-shadow: none; }
}