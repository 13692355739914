.voting-container-start {
  opacity: 0.01;
  max-height: 0;
}

.voting-container-space {
  opacity: 0.01;
  max-height: 350px;
  transition: max-height 350ms ease-in;
}

.voting-container-visible {
  opacity: 1;
  max-height: 350px;
  transition: opacity 500ms ease-in;
}

.voting-game-block {
  display: inline-block;
}

.voting-clickable-gif {
  cursor: pointer;
}