.full-screen {
  height: 100vh;
}

table {
  border: none;
}

tr {
  border-top: none;
  border-bottom: 1px solid var(--dark-lighter);
  white-space: nowrap;
}

tr:last-child {
  border-bottom: none;
}

#players-table td, #scores-table td {
  border-top: none;
  border-bottom: none;
}

.table td.fit, 
.table th.fit {
  white-space: nowrap;
  width: 1%;
}