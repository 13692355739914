.game-gif-sm {
  height: 72px;
  width: 72px;
}

.game-gif-md {
  height: 100px;
  width: 100px;
}

.game-gif-lg {
  height: 200px;
  width: 200px;
}

.game-gif-xl {
  height: 400px;
  width: 400px;
}

.game-gif-border-sm {
  padding: 4px;
  border-radius: 2px;
  background: var(--secondary);
  box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.25);
}

.game-gif-border-md {
  padding: 4px;
  border-radius: 2px;
  background: var(--secondary);
  box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.25);
}

.game-gif-border-lg {
  padding: 8px;
  border-radius: 4px;
  background: var(--secondary);
  box-shadow: 0px 0px 32px 8px rgba(0,0,0,0.2);
}

.game-gif-border-xl {
  padding: 16px;
  border-radius: 8px;
  background: var(--secondary);
  box-shadow: 0px 0px 64px 16px rgba(0,0,0,0.25);
}