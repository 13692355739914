.profile-pic-xs {
  height: 18px;
  width: 18px;
  border-radius: 1px;
}
.profile-pic-sm {
  height: 30px;
  width: 30px;
  border-radius: 2px;
}

.profile-pic-md {
  height: 54px;
  width: 54px;
  border-radius: 4px;
}

.profile-pic-lg {
  height: 72px;
  width: 72px;
  border-radius: 5px;
}