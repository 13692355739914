.game-instructions-container {
  background: var(--dark);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 9999;
}

.full-screen {
  height: 100vh;
}
