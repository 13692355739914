/*
Setting a variable’s value...

document
  .documentElement.style.setProperty("--color-surface", "black");
*/

:root {
  --dark: #2c3e50 !important;
  --dark-lighter: #34495e !important;
  --light: #ffffff !important;
  --light-darker: #dddddd !important;
  --primary: #3498db !important;
  --primary-darker: #2980b9 !important;
  --secondary: #1abc9c !important;
  --secondary-darker: #16a085 !important;
  --success: #2ecc71 !important;
  --success-darker: #27ae60 !important;
  --warning: #f39c12 !important;
  --warning-darker: #e67e22 !important;
  --danger: #e74c3c !important;
  --danger-darker: #c0392b !important;
}

html, body, table /*, div, p, h1, h2, h3, h4, h5*/ {
  background: var(--dark) !important;
  color: var(--light) !important;
}

/* take you to button town */
.btn-dark {
  background: var(--dark-lighter) !important;
  color: var(--light) !important;
  border-color: var(--dark-lighter) !important;
}
.btn-dark:hover {
  background: var(--dark) !important;
  color: var(--light) !important;
  border-color: var(--dark) !important;
}
.btn-light {
  background: var(--light) !important;
  color: var(--bg-dark) !important;
  border-color: var(--light) !important;
}
.btn-light:hover {
  background: var(--light-darker) !important;
  color: var(--dark) !important;
  border-color: var(--light-darker) !important;
}
.btn-primary {
  background: var(--primary) !important;
  color: var(--light) !important;
  border-color: var(--primary) !important;
}
.btn-primary:hover {
  background: var(--primary-darker) !important;
  color: var(--light) !important;
  border-color: var(--primary-darker) !important;
}
.btn-secondary {
  background: var(--secondary) !important;
  color: var(--light) !important;
  border-color: var(--secondary) !important;
}
.btn-secondary:hover {
  background: var(--secondary-darker) !important;
  color: var(--light) !important;
  border-color: var(--secondary-darker) !important;
}
.btn-success {
  background: var(--success) !important;
  color: var(--light) !important;
  border-color: var(--success) !important;
}
.btn-success:hover {
  background: var(--success-darker) !important;
  color: var(--light) !important;
  border-color: var(--success-darker) !important;
}
.btn-warning {
  background: var(--warning) !important;
  color: var(--light) !important;
  border-color: var(--warning) !important;
}
.btn-warning:hover {
  background: var(--warning-darker) !important;
  color: var(--light) !important;
  border-color: var(--warning-darker) !important;
}
.btn-danger {
  background: var(--danger) !important;
  color: var(--light) !important;
  border-color: var(--danger) !important;
}
.btn-danger:hover {
  background: var(--danger-darker) !important;
  color: var(--light) !important;
  border-color: var(--danger-darker) !important;
}

/* take you to background town */
.bg-dark { background: var(--dark) !important; }
.bg-dark-lighter { background: var(--dark-lighter) !important; }
.bg-light { background: var(--light) !important; }
.bg-light-darker { background: var(--light-darker) !important; }
.bg-primary { background: var(--primary) !important; }
.bg-secondary { background: var(--secondary) !important; }
.bg-success { background: var(--success) !important; }
.bg-warning { background: var(--warning) !important; }
.bg-danger { background: var(--danger) !important; }

/* take you to text town */
.text-dark { color: var(--dark) !important; }
.text-dark-lighter { color: var(--dark-lighter) !important; }
.text-light { color: var(--light) !important; }
.text-light-darker { color: var(--light-darker) !important; }
.text-primary { color: var(--primary) !important; }
.text-secondary { color: var(--secondary) !important; }
.text-success { color: var(--success) !important; }
.text-warning { color: var(--warning) !important; }
.text-danger { color: var(--danger) !important; }